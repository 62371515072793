/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
  outline: none;
  color: inherit;
}

a:hover,
a:active,
a:visited {
  text-decoration: none;
  color: inherit;
}

@font-face {
  font-family: 'Noto Sans KR';
  src: url('./assets/fonts/Noto_Sans_KR/NotoSansKR-Regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'Noto Sans KR';
  src: url('./assets/fonts/Noto_Sans_KR/NotoSansKR-Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Noto Sans KR';
  src: url('./assets/fonts/Noto_Sans_KR/NotoSansKR-Bold.otf');
  font-weight: 700;
}

/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap'); */

* {
  font-family: 'Noto Sans KR', 'sans-serif', '애플 SD 산돌고딕 Neo', 'Apple SD Gothic Neo', '맑은 고딕';
}
